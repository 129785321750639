<template>
  <div class="billing-docs">
    <div class="billing-docs__head">
      <div class="billing-docs__nav">
        <tabs :list="nav" theme="pills" class="billing-docs__nav-tabs">
          <template v-slot:item="{ item }">
            <router-link exact-active-class="active" :to="item.to">
              {{ item.title }}
            </router-link>
          </template>
        </tabs>
      </div>
      <div class="billing-docs__filter">
        <base-datepicker-filter
          v-if="itemtype !== 'contract'"
          :periods="periods"
          :period="period"
          :value="dateFilter"
          class="billing-docs__filter-item"
          @init="onFilterInit"
          @change="onFilterChange"
        />
      </div>
    </div>
    <div class="billing-docs__content">
      <router-view :itemtype="itemtype" :date-filter="dateFilter" />
    </div>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
import BaseDatepickerFilter from '@/components/BaseDatepicker/BaseDatepickerFilter.vue';
export default {
  name: 'BillingDocs',
  components: { tabs, BaseDatepickerFilter },
  data() {
    return {
      nav: [
        {
          title: this.$t('nav.payments'),
          to: { name: 'BillingPayments' },
        },
        /*{
          title: this.$t('nav.invoices'),
          to: { name: 'BillingInvoices' },
        },*/
        {
          title: this.$t('nav.contracts'),
          to: { name: 'BillingContracts' },
        },
        {
          title: this.$t('nav.customdocs'),
          to: { name: 'BillingCustomdocs' },
        },
      ],
      dateFilter: { start: new Date(), end: new Date() },
      periods: ['month', 'quarter', 'halfyear', 'year'],
      period: 'year',
    };
  },
  computed: {
    itemtype() {
      return this.$route.meta.itemtype;
    },
  },
  methods: {
    onFilterInit(value) {
      this.dateFilter = value;
    },
    onFilterChange(value) {
      // console.log(value);
      this.dateFilter = value;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "export": "Экспортировать в CSV",
    "nav": {
      "payments": "Счета",
      "invoices": "Акты",
      "contracts": "Договоры",
      "customdocs": "Закрывающие документы"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.billing-docs {
  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      flexy(flex-start, center, wrap);
    }
    +breakpoint(md-and-up) {
      //flex-wrap: nowrap;
    }
  }
  &__nav {
    flex: 1 0 100%;
    +breakpoint(lg-and-up) {
      flex: 0 0 auto;
    }
  }
  &__filter {
    margin: 1rem 0;

    +breakpoint(sm-and-up) {
      margin-right: auto;
    }
    +breakpoint(lg-and-up) {
      margin: 0 1rem;
      margin-right: auto;
    }
  }
  &__export {
    +breakpoint(sm-and-up) {
      margin: 1rem 0 1rem auto;
    }
  }
}
</style>
