export default {
  computed: {
    contextMenu() {
      if (!this.current) return [];
      const { pdfdownload, print, request } = this.tools;
      const { sender_name } = this.current;
      if (!pdfdownload && !print) return [];
      const arr = [];
      // костыль для пустых плательщиков
      if (pdfdownload && sender_name !== ' - ') {
        arr.push({
          key: 'download',
          handler: this.docRedirect,
          tool: pdfdownload,
          disabled: !pdfdownload.isEnable(this.current),
        });
      }
      if (print && sender_name !== ' - ') {
        arr.push({
          key: 'print',
          handler: this.docRedirect,
          tool: print,
          disabled: !print.isEnable(this.current),
        });
      }
      if (print && sender_name === ' - ') {
        arr.push({
          key: 'list',
          handler: this.docRedirect,
          tool: print,
          disabled: !print.isEnable(this.current),
        });
      }
      if (request) {
        arr.push({
          key: 'request',
          tool: request,
          disabled: !request.isEnable(this.current),
        });
      }
      if (this.tools.delete) {
        arr.push({
          key: 'delete',
          color: 'del',
          askSure: true,
          tool: this.tools.delete,
          disabled: !this.tools.delete.isEnable(this.current),
        });
      }
      return [arr];
    },
  },
};
